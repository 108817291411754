import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  API_ENDPOINT,
  BASE_URL,
  CONTENT_URL,
  GENERAL_PATH,
  IMAGE_URL,
  PUSHER_KEY,
} from "../../api/api-config";
import banjosApi from "../../api/banjosApi";
import moment from "moment";
import axios from "axios";
import { Loading } from "../../components/shared/UI/Loading";
import Pusher from "pusher-js";
import { useLogger } from "../../hooks/useLogger";
import { getTenantId, hasKey } from "../../helper/Common";
import queryString from "query-string";
import ReactPlayer from "react-player";
import { useMainStore } from "../../Store/MainStore";
import { useDevice } from "../../hooks/useDevice";
import { deviceDetect } from "react-device-detect";
import {
  SwitchTransition,
  CSSTransition,
  TransitionGroup,
} from "react-transition-group";
import { useCacheStorage } from "../../hooks/useCacheStorage";
import BFIT_HORIZONTAL from "../../assets/ph_horizontal.png";
import BFIT_VERTICAL from "../../assets/ph_vertical.png";

export const Signage = (props) => {
  const history = useHistory();
  const { AppState } = useMainStore();
  const [isOnline, setOnline] = useState(true);

  const [state, setState] = useImmer({
    loadingPercent: 0,
    currentIndex: 0,
    isRefreshed: false,
    scheduleChanged: false,
    liveView: false,
    triggerLive: false,
    isBusy: false,
    members: {},
    schedules: [],
    activeItem: [],
    pusher: "",
    timeInfo: {
      UTC: null,
    },
    channelInfo: null,
  });
  const { onRegister, GetDeviceInfo } = useDevice(false);
  const deviceInfo = JSON.parse(localStorage.getItem("DeviceInfo"));
  const { LogError, LogInformation, LogToExternal } = useLogger();
  const { cacheData } = useCacheStorage();

  const ShowError = () => {
    throw new Error("Test error");
  };
  const getScreenInfo = () => {
    return {
      width: window.screen.width,
      height: window.screen.height,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
      orientation: window.screen.orientation,
    };
  };
  //Network Change
  const onNetworkChange = ({ type }) => {
    if (type === "online") {
      // window.location.reload();
      setState((draft) => {
        draft.isRefreshed = true;
      });
    }
  };

  //To load images
  const loadContent = (content) =>
    new Promise((resolve, reject) => {
      try {
        fetch(CONTENT_URL + content.file.fileName)
          .then((res) => {
            cacheData(CONTENT_URL + content.file.fileName, "contents", res);
            resolve(content);
          })
          .catch((err) => reject(content));
      } catch (error) {
        LogToExternal("error", error);
        // console.log("content preload", error);
      }
    });

  const preloadContents = (contents) => {
    let Contents = [];
    setState((draft) => {
      draft.isBusy = true;
    });
    contents.forEach((content) => {
      const fileInfo = JSON.parse(content.file.info);
      Contents.push(loadContent(content));
    });

    Promise.all(Contents)
      .then((res) => {
        //console.log("Completed", res);
        setState((draft) => {
          draft.isBusy = false;
        });
      })
      .catch((err) => {
        //console.log("Failed", err);
        // LogError(1, `Image preload failed ${JSON.stringify(err)}`);
        LogToExternal("error", err);
        setState((draft) => {
          draft.isBusy = false;
        });
      });
  };

  const refreshSchedule = () => {
    try {
      getTime();
      if (deviceInfo) {
        setState((draft) => {
          draft.isBusy = true;
        });
        if (!deviceInfo.accessToken) {
          // console.log("No Token");
          onRegister(deviceInfo.deviceId, deviceDetect(), true);
          //send registration request
        } else {
          //get Slides
          banjosApi
            .get(
              `schedule/GetSchedule/${deviceInfo.groupId}/${deviceInfo.accessToken}`
            )
            .then((response) => {
              // setSchedules((draft) => {
              //   return response.data;
              // });
              setState((draft) => {
                draft.schedules = response.data;
                draft.isRefreshed = false;
                draft.isBusy = false;
              });
              LogError(
                0,
                `Schedule Refreshed ${JSON.stringify(response.data)}`
              );
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data === "Device token is invalid") {
                  localStorage.removeItem("DeviceInfo");
                  window.location.reload();
                }
              }
              LogToExternal("error", error);
              LogError(1, `Schedule Refresh Failed ${error.message}`);
              setState((draft) => {
                draft.isRefreshed = false;
                draft.isBusy = false;
              });
            });
        }
      } else if (!getTenantId() && !props.location.search) {
        history.push("/login");
      } else {
        history.push("/device/config");
      }
    } catch (error) {
      LogToExternal("error", error);
      LogError(1, `Exception:refreshSchedule ${error.message}`);
      // console.log("Exception:refreshSchedule", error);
    }
  };

  //To get current time for the server
  const getTime = () => {
    try {
      if (deviceInfo) {
        axios
          .get(API_ENDPOINT + `default/time/${deviceInfo.timeZone}`)
          .then((res) => {
            setState((draft) => {
              draft.timeInfo.UTC = res.data.datetime;
            });
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    } catch (error) {
      LogToExternal("error", error);
      LogError(1, `Exception:getTime ${error.message}`);
      // console.log("Exception:getTime", error);
    }
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === 413) {
      event.preventDefault();
      history.push("/device/config");
    }
  };
  // Start Pusher
  const startPusher = () => {
    // Pusher.logToConsole = true;

    try {
      if (deviceInfo) {
        var pusher = new Pusher(PUSHER_KEY, {
          cluster: "ap4",
          authEndpoint: API_ENDPOINT + "pusher/auth",
          auth: {
            params: { deviceId: deviceInfo.deviceId },
          },
        });

        var channel = pusher.subscribe("banjos-signage");
        var presenceChannel = pusher.subscribe("presence-banjos-signage");
        var deviceChannel = pusher.subscribe(
          `presence-device-${deviceInfo.deviceId}`
        );

        deviceChannel.bind("pusher:subscription_succeeded", (members) => {
          setState((draft) => {
            draft.liveView = hasKey(deviceChannel.members.members, "live");
          });
        });

        deviceChannel.bind("pusher:member_added", (members) => {
          setState((draft) => {
            draft.liveView = hasKey(deviceChannel.members.members, "live");
          });
        });

        deviceChannel.bind("pusher:member_removed", (members) => {
          setState((draft) => {
            draft.liveView = hasKey(deviceChannel.members.members, "live");
          });
        });

        setState((draft) => {
          draft.channelInfo = deviceChannel;
        });
        deviceChannel.bind("client-live-mode", (data) => {
          // console.log("live-mode", data.status);
          setState((draft) => {
            draft.triggerLive = data.status;
          });
        });
        channel.bind("force-reload", function (data) {
          if (data) {
            if (data.reloadType === 0) {
              if (
                (data.timeZone === "All" && data.tid === getTenantId()) ||
                data.timeZone === deviceInfo.timeZone
              ) {
                window.location.reload();
              }
              LogError(0, `Force Reload ${JSON.stringify(data)}`);
            } else if (
              data.reloadType === 2 &&
              deviceInfo.deviceId === data.deviceId
            ) {
              // Reload Device
              //console.log("Force Reload Device");
              window.location.reload();
            } else if (data.timeZone === deviceInfo.timeZone) {
              LogError(0, `Force Reload  ${JSON.stringify(data)}`);
              // setState((draft) => {
              //   draft.isRefreshed = true;
              // });
              window.location.reload();
            } else if (data.reloadType === 3) {
              //Force URL Change
              if (deviceInfo) {
                LogError(
                  0,
                  `Force Reload : URL Change  ${JSON.stringify(data)}`
                );
                window.location.href = `${data.URL}/config/?tid=${data.tid}&accessToken=${deviceInfo.accessToken}&deviceId=${deviceInfo.deviceId}&branchId=${deviceInfo.branchId}&groupId=${deviceInfo.groupId}&timeZone=${deviceInfo.timeZone}&orientation=${deviceInfo.orientation}`;
              } else {
                LogError(
                  1,
                  `Force Reload : URL Change Failed ${JSON.stringify(data)}`
                );
              }
            } else if (data.reloadType === 4 && data.tid === getTenantId()) {
              // console.log("Force reload", data);
              //Force URL Change
              if (deviceInfo) {
                if (deviceInfo.deviceId === data.deviceId) {
                  LogError(
                    0,
                    `Force Reload : URL Change  ${JSON.stringify(data)}`
                  );
                  window.location.href = `${data.URL}/config/?tid=${data.tid}&accessToken=${deviceInfo.accessToken}&deviceId=${deviceInfo.deviceId}&branchId=${deviceInfo.branchId}&groupId=${deviceInfo.groupId}&timeZone=${deviceInfo.timeZone}&orientation=${deviceInfo.orientation}`;
                }
              } else {
                LogError(
                  1,
                  `Force Reload : URL Change Failed ${JSON.stringify(data)}`
                );
              }
            }
          }
        });
        pusher.connection.bind("state_change", function (states) {
          // states = {previous: 'oldState', current: 'newState'}
          setState((draft) => {
            draft.pusher = states.current;
          });
        });
      }
    } catch (error) {
      LogToExternal("error", error);
      LogError(1, `Exception:startPusher ${error.message}`);
      // console.log("Exception:startPusher", error);
    }
  };

  useEffect(() => {
    try {
      if (props.location.search) {
        const params = queryString.parse(props.location.search);
        if (params.tid) localStorage.setItem("tid", params.tid);
        GetDeviceInfo(params.accessToken)
          .then((res) => {
            const deviceInfo = {
              name: res.data.name,
              deviceId: parseInt(res.data.deviceId),
              branchId: parseInt(res.data.branchId),
              groupId: parseInt(res.data.groupId),
              timeZone: params.timeZone,
              orientation: parseInt(res.data.orientation),
              accessToken: res.data.accessToken,
            };
            localStorage.setItem("DeviceInfo", JSON.stringify(deviceInfo));
            localStorage.setItem("fr", 1);
            window.location.href = "/";
          })
          .catch((err) => {
            LogError(1, `Exception:useEffect URL Reload ${err.message}`);
          });
      }
    } catch (error) {
      LogToExternal("error", error);
      LogError(1, `Exception:useEffect URL Reload ${error.message}`);
    }
  }, [props.location.search]);

  //Pusher
  useEffect(() => {
    //To force reload the device
    //Added for latest version upgrade, can be removed.
    if (+localStorage.getItem("fr") === 1) {
      localStorage.removeItem("fr");
      window.location.reload();
    }
    window.addEventListener("online", onNetworkChange);
    window.addEventListener("offline", onNetworkChange);
    window.addEventListener("keydown", handleKeyPress);
    startPusher();
    refreshSchedule();
    return () => {
      window.removeEventListener("online", onNetworkChange);
      window.removeEventListener("offline", onNetworkChange);
    };
  }, []);

  //Pusher Refresh
  useEffect(() => {
    if (state.isRefreshed === true) {
      refreshSchedule();
    }
  }, [state.isRefreshed]);

  useEffect(() => {
    if (state.triggerLive) {
      updateLiveMonitor();
      setState((draft) => {
        draft.triggerLive = false;
      });
    }
    return () => {};
  }, [state.triggerLive]);

  useEffect(() => {
    getTime();
    return () => {};
  }, [state.scheduleChanged]);

  //Set Active Schedule
  useEffect(() => {
    try {
      const dateTimeFormat = "DD-MM-YYYY hh:mm:ss A";
      const timeFormat = "hh:mm:ss A";

      if (state.schedules.length > 0 && state.timeInfo.UTC !== null) {
        var currentTime = moment(state.timeInfo.UTC, dateTimeFormat).format(
          timeFormat
        );
        // console.log("currentTime", currentTime);

        var activeSchedules = state.schedules.filter((c) =>
          moment(currentTime, timeFormat).isBefore(moment(c.endAt, timeFormat))
        );

        // console.log("active schedules", activeSchedules);

        if (activeSchedules.length > 0) {
          var scheduleStart = `${moment(state.timeInfo.UTC).format(
            "DD-MM-YYYY"
          )} ${activeSchedules[0].startAt}`;
          var scheduleEnd = `${moment(state.timeInfo.UTC).format(
            "DD-MM-YYYY"
          )} ${activeSchedules[0].endAt}`;
          var now = `${moment(state.timeInfo.UTC).format(
            "DD-MM-YYYY"
          )} ${currentTime}`;
          // console.log("end Time", scheduleEnd);

          var countDown = moment
            .duration(
              moment(scheduleStart, dateTimeFormat).diff(
                moment(now, dateTimeFormat)
              )
            )
            .asMilliseconds();

          if (countDown > 3000) {
            setState((draft) => {
              draft.activeItem = [];
            });
          }

          var startTimer = setTimeout(() => {
            setState((draft) => {
              draft.currentIndex = 0;
              draft.activeItem =
                activeSchedules.length > 0 ? activeSchedules[0] : [];
              draft.scheduleChanged = false;
            });
          }, countDown);

          var timeLeft = moment
            .duration(
              moment(scheduleEnd, dateTimeFormat).diff(
                moment(now, dateTimeFormat)
              )
            )
            .asMilliseconds();

          var endTimer = setTimeout(() => {
            // console.log("end timer triggered", timeLeft);
            setState((draft) => {
              draft.currentIndex = 0;
              draft.scheduleChanged = true;
            });
          }, timeLeft);

          // console.log("Active Schedules", activeSchedules);
        } else {
          setState((draft) => {
            draft.activeItem = [];
          });
        }
      }
      return () => {
        clearTimeout(endTimer);
        clearTimeout(startTimer);
      };
    } catch (error) {
      LogToExternal("error", error);
      LogError(1, `Exception:SetActiveScheduleHook ${error.message}`);
      // console.log("Exception:SetActiveScheduleHook", error);
    }
  }, [state.schedules, state.timeInfo.UTC]);

  //Preload Images
  useEffect(() => {
    if (state.activeItem.scheduleId > 0) {
      preloadContents(state.activeItem.playlist.playlistContents);
    }
  }, [state.activeItem]);

  const updateLiveMonitor = () => {
    // console.log("updateLiveMonitor", state.liveView);
    if (state.liveView) {
      if (state.channelInfo) {
        // console.log("Content change");
        state.channelInfo.trigger("client-live-screen", {
          isIdle: state.activeItem.scheduleId > 0 ? false : true,
          playlist:
            state.activeItem.scheduleId > 0
              ? state.activeItem.playlist.name
              : "idle",
          file:
            state.activeItem.scheduleId > 0
              ? state.activeItem.playlist.playlistContents[state.currentIndex]
                  .file.fileName
              : deviceInfo.orientation === 1
              ? "idle_hor"
              : "idle_ver",
          screenInfo: getScreenInfo(),
        });
      }
    }
  };

  //Content change
  useEffect(() => {
    try {
      // Send live content to monitor
      updateLiveMonitor();
      if (state.activeItem.scheduleId > 0) {
        const duration =
          state.activeItem.playlist.playlistContents[state.currentIndex]
            .duration > 0
            ? state.activeItem.playlist.playlistContents[state.currentIndex]
                .duration
            : state.activeItem.playlist.duration;

        const interval = setInterval(() => {
          changeContent();
        }, duration * 1000);
        return () => clearInterval(interval);
      }
    } catch (error) {
      LogToExternal("error", error);
      // console.log("Exception:ContentChangeHook", error.message);
      LogError(1, `Exception:ContentChangeHook ${error.message}`);
      // console.log("Exception:ContentChangeHook", error);
    }
  }, [state.activeItem, state.currentIndex]);

  function changeContent() {
    // console.log("State", state.liveView);
    if (state.activeItem.scheduleId > 0) {
      var _currentIndex = state.currentIndex;
      if (
        _currentIndex <
        state.activeItem.playlist.playlistContents.length - 1
      ) {
        setState((draft) => {
          draft.currentIndex = _currentIndex + 1;
        });
      } else {
        setState((draft) => {
          draft.currentIndex = 0;
        });
      }
    }
  }
  const hanldeError = (error, info) => {
    // console.log("Error test", error);
  };
  const getIdleImage = (orientation) => {
    let idleImage = "";
    if (orientation === 1) {
      if (AppState.config?.placeholderHorizontal)
        idleImage = `${GENERAL_PATH}${AppState.config?.placeholderHorizontal}`;
      else idleImage = BFIT_HORIZONTAL;
    } else {
      if (AppState.config?.placeholderVertical)
        idleImage = `${GENERAL_PATH}${AppState.config?.placeholderVertical}`;
      else idleImage = BFIT_VERTICAL;
    }
    return idleImage;
  };
  const GetContent = () => {
    const content =
      state.activeItem.playlist.playlistContents[state.currentIndex].file;
    const fileInfo = JSON.parse(content.info);
    return (
      <CSSTransition
        classNames="fade"
        timeout={{ enter: 1000, exit: 1000 }}
        key={content.fileName}
      >
        <>
          {fileInfo.ContentType.includes("video") ? (
            <ReactPlayer
              url={CONTENT_URL + content.fileName}
              className="signage-content"
              height="100%"
              width="100%"
              playing={true}
              muted={true}
              loop={true}
              onError={(err) => console.log("playback error", err)}
            ></ReactPlayer>
          ) : (
            <img
              alt="Content"
              className="signage-content"
              // onLoad={(e) => console.log("onLoad", e)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  deviceInfo && getIdleImage(deviceInfo.orientation);
              }}
              src={CONTENT_URL + content.fileName}
            />
          )}
        </>
      </CSSTransition>
    );
    // try {
    //   const content =
    //     state.activeItem.playlist.playlistContents[state.currentIndex].file;
    //   const fileInfo = JSON.parse(content.info);
    //   if (fileInfo.ContentType.includes("video")) {
    //     //video
    //     return (
    //       <ReactPlayer
    //         url={CONTENT_URL + content.fileName}
    //         className="signage-content"
    //         height="100%"
    //         width="100%"
    //         playing={true}
    //         muted={true}
    //         onError={(err) => console.log("playback error", err)}
    //       ></ReactPlayer>
    //     );
    //   } else {
    //     return (
    //       <TransitionGroup>
    //         <CSSTransition
    //           classNames="slide"
    //           timeout={{ enter: 1500, exit: 1500 }}
    //           key={content.fileName}
    //         >
    //           <img
    //             alt="Content"
    //             className="signage-content"
    //             onError={(e) => {
    //               e.target.onerror = null;
    //               e.target.src =
    //                 deviceInfo && getIdleImage(deviceInfo.orientation);
    //             }}
    //             src={CONTENT_URL + content.fileName}
    //           />
    //         </CSSTransition>
    //       </TransitionGroup>
    //     );
    //   }
    //   // return (
    //   //   CONTENT_URL +
    //   //   state.activeItem.playlist.playlistContents[state.currentIndex].file
    //   //     .fileName
    //   // );
    // } catch (error) {
    //   LogError(
    //     1,
    //     `Exception:getContent ${error.message}, Info : ${JSON.stringify({
    //       playlist: state.activeItem.playlist.name,
    //       currentIndex: state.currentIndex,
    //     })}`
    //   );
    //   return (
    //     <img
    //       alt=""
    //       className="signage-content"
    //       src={deviceInfo && getIdleImage(deviceInfo.orientation)}
    //     />
    //   );
    // }
  };

  if (state.isBusy) {
    return <Loading text="Loading contents..." />;
  }

  return (
    <div className="background" style={{ backgroundColor: "#000" }}>
      {/* <ShowError /> */}
      {state.activeItem.scheduleId > 0 &&
      state.activeItem.playlist.playlistContents.length > 0 &&
      state.currentIndex < state.activeItem.playlist.playlistContents.length ? (
        <TransitionGroup>{GetContent()}</TransitionGroup>
      ) : (
        <img
          alt="idle"
          className="signage-content"
          src={deviceInfo && getIdleImage(deviceInfo.orientation)}
        />
      )}
    </div>
  );
};
